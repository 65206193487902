<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <span v-if="forManagers">
        <small class="fas fa-angle-right mx-1"></small>
        <a href="/customers">Customers</a>
        <small class="fas fa-angle-right mx-1"></small>
        <a :href="`/customers/new`">New Customer</a>
      </span>
      <span v-else>
        <small class="fas fa-angle-right mx-1"></small>
        <a href="/users">Users</a>
        <small class="fas fa-angle-right mx-1"></small>
        <a :href="`/users/new`">New User</a>
      </span>
    </div>
    <div class="row issue-wrapper">
      <div class="col col-auto-7 issue-col p-4 bg-white">
        <div class="row px-2">
          <div class="col-12 pt-2">
            <h6 class="text-dark text-header">
              Full Name
              <small class="text-secondary cursor-pointer float-right mt-2" v-on:click="toggleAvatar">Change avatar</small>
            </h6>
            <input class="form-control" placeholder="Full name" v-model="userForm.name"/>
            <br>
            <h6 class="text-dark text-header">Email Address</h6>
            <input class="form-control" placeholder="Email Address" v-model="userForm.email"/>
            <br>
            <h6 class="text-dark text-header">Phone Number</h6>
            <input class="form-control" placeholder="Phone Number" v-model="userForm.phoneNumber"/>
            <br>
            <h6 class="text-dark text-header">Mobile Number</h6>
            <input class="form-control" placeholder="Mobile Number" v-model="userForm.mobileNumber"/>
            <br>
            <div class="row">
              <div class="col">
                <h6 class="text-dark text-header">User Role</h6>
                <ModelSelect
                  v-model="userRole"
                  placeholder="Select user role"
                  :options="forManagers ? options.client_roles.filter(r => !r.disabled) : options.all_roles.filter(r => !r.disabled)"
                >
                </ModelSelect>
              </div>
              <div class="col" v-if="['Manager', 'Manager & Agent', 'Agent'].includes(userRole.value)">
                <h6 class="text-dark text-header">
                  Gitlab Token &nbsp;
                  <i class="fas fa-info-circle cursor-pointer" v-tooltip="{ content: `Login to tools.iripple.com, then go to Settings > Access Tokens. Fill up the necessary fields and generate your own Gitlab personal token.`, placement: 'right' }"></i>
                </h6>
                <input class="form-control" placeholder="Gitlab Token" v-model="userForm.gitlabPersonalToken"/>
              </div>
              <div class="col" v-else>
                <h6 class="text-dark text-header">Client</h6>
                <ModelSelect
                  v-model="client"
                  placeholder="Client"
                  :options="options.clients"
                >
                </ModelSelect>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col">
                <h6 class="text-dark text-header">Password</h6>
                <input class="form-control" :type="showPassword ? `text` : `password`" placeholder="Password" v-model="userForm.password"/>
                <div
                  class="btn btn-muted btn-sm p-3 float-right"
                  @mouseover="showPassword = true"
                  @mouseleave="showPassword = false"
                  style="position: relative; margin-top: -50px; z-index: 999"
                >
                  <i class="text-secondary fas" :class="showPassword ? `fa-eye-slash` : `fa-eye`"/>
                </div>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Confirm Password</h6>
                <input class="form-control" :type="showConfirmPassword ? `text` : `password`" placeholder="Confirm password" v-model="userForm.confirmPassword"/>
                <div
                  class="btn btn-muted btn-sm p-3 float-right"
                  @mouseover="showConfirmPassword = true"
                  @mouseleave="showConfirmPassword = false"
                  style="position: relative; margin-top: -50px; z-index: 999"
                >
                  <i class="text-secondary fas" :class="showConfirmPassword ? `fa-eye-slash` : `fa-eye`"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <br>
            <div class="w-100 text-right">
              <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="createUser">
                <i class="btn p-0 text-white fas fa-save"></i>&nbsp; Create user
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 issue-col issue-properties shadow-0 border-md-left p-0 m-0 element-disabled">
        <div class="row px-2 m-0 h-100 w-100">
          <div class="col-md-12 p-4 issue-col bg-white">
            <div class="mb-3 pb-4 border-bottom">
              <div class="property-dynamic">
                <h6 class="pt-3 text-dark text-header">Business Unit</h6>
                <ModelSelect
                  v-model="bunit"
                  :isDisabled="true"
                  placeholder="Select business unit"
                  :options="options.bunits"
                >
                </ModelSelect>
              </div>
              <div class="w-100 mt-4 text-right">
                <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline element-disabled">
                  <i class="fas fa-plus"></i>&nbsp; Add project
                </div>
              </div>
            </div>
            <h6 class="text-dark text-header">Assigned Business Units</h6>
          </div>
        </div>
      </div>
    </div>
    <modal name="avatar-menu" :transition="`fade`" :width="`620px`" :height="`auto`" :scrollable="true">
      <div class="p-5 row">
        <span v-for="avatar in $avatars" v-bind:key="avatar" class="col col-3">
          <img
            class="profile-avatar m-0 p-3"
            v-bind:class="[`/images/avatars/${avatar}` === userForm.avatar ? 'avatar-active' : '']"
            v-on:click="setAvatar(`/images/avatars/${avatar}`)"
            :src="$getAvatar(`/images/avatars/${avatar}`)"
          />
        </span>
      </div>
    </modal>
  </div>
</template>

<script>
  import { ModelSelect } from 'vue-search-select'

  export default {
    components: {
      ModelSelect
    },
    data() {
      return {
        userForm: {
          name: '',
          email: '',
          mobileNumber: '',
          phoneNumber: '',
          isAgent: false,
          isActive: true,
          avatar: '/images/avatars/avatarArtboard-1.svg',
          receiveEmail: true,
          password: '',
          confirmPassword: '',
          gitlabPersonalToken: '',
          roles: null,
          client: null
        },
        showPassword: false,
        showConfirmPassword: false,
        client: {},
        userRole: this.$userData.user.role === 'Admin' ? { key: 'Manager', text: 'Manager', value: 'Manager', disabled: false, data: {isAgent: false, role: 'Manager'} } : { key: 'Customer', text: 'Customer', value: 'Customer', disabled: false, data: {isAgent: false, role: 'Customer'} },
        bunit: {},
        options: {
          bunits: [],
          all_roles: [
            { key: 'Manager', text: 'Manager', value: 'Manager', disabled: false, data: {isAgent: false, role: 'Manager'} },
            { key: 'Manager & Agent', text: 'Manager & Agent', value: 'Manager & Agent', disabled: false, data: {isAgent: true, role: 'Manager'} },
            { key: 'Agent', text: 'Agent', value: 'Agent', disabled: false, data: {isAgent: false, role: 'Agent'} },
            { key: 'Customer', text: 'Customer', value: 'Customer', disabled: false, data: {isAgent: false, role: 'Customer'} }
          ],
          client_roles: [
            { key: 'Customer', text: 'Customer', value: 'Customer', disabled: false, data: {isAgent: false, role: 'Customer'} }
          ],
          notifications: [
            { key: 'active', text: 'Receive email notifications', value: true },
            { key: 'inactive', text: 'Suspend email notifications', value: false }
          ],
          clients: []
        }
      }
    },
    props: {
      forManagers: Boolean
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      async getClients(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.clients = response.data.clients.map(r => {
            return { key: r._id, text: r.name, value: r._id, data: r }
          })
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      validateUser: async function(){
        if(!(this.$userData.user.role === 'Admin' || (this.$userData.user.role === 'Manager' && this.forManagers))){
          this.$alertify({
             group: 'notification',
             title: `Access denied`,
             type: 'warning',
             text: `You don't have access to this module.`
          })
          await this.$sleep(500);
          window.history.back()
        }
      },
      createUser: function (){
        this.errors = []
        this.userForm.isAgent = this.userRole.data ? this.userRole.data.isAgent : null
        this.userForm.roles = this.userRole.data ? this.userRole.data.role : null
        this.userForm.client = this.client.data

        this.userForm.name = this.userForm.name.trim()
        this.userForm.email = this.userForm.email.trim()
        this.userForm.mobileNumber = this.userForm.mobileNumber.trim()
        this.userForm.phoneNumber = this.userForm.phoneNumber.trim()
        this.userForm.password = this.userForm.password.trim()
        this.userForm.confirmPassword = this.userForm.confirmPassword.trim()
        this.userForm.gitlabPersonalToken = this.userForm.gitlabPersonalToken.trim()

        if(this.userForm.name === '')
          this.errors.push('User name cannot be empty')
        if(this.userForm.email === '')
          this.errors.push('Email address cannot be empty')
        if(!this.$validateEmail(this.userForm.email))
          this.errors.push('Invalid email address')
        if(this.userForm.mobileNumber !== '' && !(/^\d+$/.test(this.userForm.mobileNumber)))
          this.errors.push('Mobile number cannot contain alphanumeric values')
        if(this.userForm.phoneNumber !== '' && !(/^\d+$/.test(this.userForm.phoneNumber)))
          this.errors.push('Phone number cannot contain alphanumeric values')
        if(this.userForm.password.length < 6)
          this.errors.push('Password must have 6 or more characters')
        if(this.userForm.confirmPassword !== this.userForm.password)
          this.errors.push('Confirm password does not match password')
        if(!this.userForm.roles)
          this.errors.push('Choose a role for this user')
        else if(this.userForm.roles === 'Customer' && !this.userForm.client)
          this.errors.push('Choose a client for this user')
        else if(this.userForm.roles !== 'Customer' && this.userForm.gitlabPersonalToken === '')
          this.errors.push('Gitlab token cannot be empty')
        if (this.errors.length > 0){
          this.errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'User Create',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else {
          this.submitUser()
        }
      },
      async submitUser(){
        this.loader(true)
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/users`,
            {
              user: this.userForm
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.user && response.data.user._id) {
            this.$alertify({
              group: 'notification',
              title: 'Create successful',
              type: 'success',
              text: 'New user has been saved.'
            })
            if(response.data.user.roles === 'Customer')
              this.$router.push(`/customers/${response.data.user._id}`)
            else
              this.$router.push(`/users/${response.data.user._id}`)
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Create Failed',
            type: 'warn',
            text: error && error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Something went wrong, please try again.'
          })
        }
      },
      toggleAvatar: function(){
        this.$modal.toggle('avatar-menu');
      },
      setAvatar: function(avatar){
        this.userForm.avatar = avatar
        this.toggleAvatar()
      }
    },
    mounted: function() {
      this.validateUser()
      this.getClients()
      document.title = `New User | iRipple Helpdesk`;
    }
  }
</script>
